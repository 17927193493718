<style>
.drag {
  cursor: grabbing;
}
</style>
<template>
  <v-container>
    <draggable v-model="modelArray" draggable=".drag" @end="drag=false" @start="drag=true">
      <v-card
          v-for="(formItem,i) in modelArray"
          :key="'formItem'+i"
          :class="activeChips[i] !== undefined && activeChips[i] !== null?'':'drag'"
          outlined
      >
        <v-container>
          <v-row>
            <v-col cols="12" md="11">
              <v-chip-group
                  column
              >
                <FormEditorChip
                    :label="formItem.label ? formItem.label:formItem.o"
                    icon="mdi-eye"
                    tooltip="Anzeigename"
                    @click="chipClick(formItem,'label',i)"
                />
                <FormEditorChip
                    :label="formItem.name"
                    icon="mdi-database"
                    tooltip="Datenbankname"
                    @click="chipClick(formItem,'db',i)"
                />
                <FormEditorChip
                    :label="formItem.type && formItem.type !==''?formItem.type:'text'"
                    icon="mdi-dresser"
                    tooltip="Eingabe-Typ"
                    @click="chipClick(formItem,'type',i)"
                />
                <FormEditorChip
                    :icon="formItem.required ? 'mdi-asterisk-circle-outline':'mdi-checkbox-blank-circle-outline'"
                    :label="formItem.required ? 'Pflichtfeld':'Optional'"
                    tooltip="Validierung der Eingabe?"
                    @click="chipClick(formItem,'validation',i)"
                />
                <FormEditorChip
                    :label="formItem.colspan !== undefined?formItem.colspan:'standard'"
                    icon="mdi-arrow-expand-horizontal"
                    tooltip="Breite anpassen"
                    @click="chipClick(formItem,'colspan',i)"
                />
                <FormEditorChip
                    icon="mdi-toggle-switch-off"
                    label="Bedingung"
                    tooltip="Anzeige-Bedingung einrichten"
                    @click="chipClick(formItem,'condition',i)"
                />
              </v-chip-group>

              <EditForm v-if="activeChips[i] !== undefined && activeChips[i] !== null" v-model="modelArray[i]"
                        :model="chipFormModels[activeChips[i]]"/>
              <template
                  v-if="activeChips[i] !== undefined && activeChips[i] === 'type' && modelArray[i].type === 'addable'">
                <v-card-text>Der Eingabetyp 'addable' ist für hinzufügbare Eingaben. Für den hinzufügbaren Datensatz
                  kann folgendes Unterformular bearbeitet werden:
                </v-card-text>

                <EditForm v-model="realSubformItem" :model="modelArray[i].subform || defaultSubForm"/>

                <v-container>
                  <v-row>
                    <v-col>
                      <v-btn color="accent"
                             @click="editSubForm(i)">Unter-Formular bearbeiten
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
                <v-dialog v-model="subFormDialog[i]">
                  <v-card>
                    <v-card-title>
                      addable-Formular bearbeiten
                    </v-card-title>
                    <v-card-text>
                      <FormEditor :ref="'subForm'+i" :formModel="modelArray[i].subform || defaultSubForm"/>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn @click="saveSubModel(i)">addable Eingabefelder Übernehmen</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>


              </template>


            </v-col>
            <v-col cols="12" md="1">
              <v-btn color="primary"
                     icon
                     small
                     @click="copyFormItem(i)">
                <v-icon dark>
                  mdi-content-copy
                </v-icon>
              </v-btn>
              <v-btn color="primary"
                     icon
                     small
                     @click="deleteFormItem(i)">
                <v-icon dark>
                  mdi-delete
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </draggable>
    <br/><br/>
    <v-chip
        small
        @click="addFormItem">
      <v-icon small>
        mdi-plus
      </v-icon>
      Eingabefeld hinzufügen
    </v-chip>
  </v-container>
</template>

<script>
import draggable from 'vuedraggable'
import FormEditorChip from "@/components/Forms/FormEditorChip";
import kontaktModel from '@/config/models/Kontakte';
import vereinsModel from '@/config/models/Vereine';
import turnierModel from '@/config/models/Turniere';
import mannschaftModel from '@/config/models/Mannschaft';
import clonedeep from "lodash.clonedeep"

//get Models for forms and overwrite the ID-Reference to store the actual String and n ot the ID when inputs where made in Campaigns
const listsConfig = [
  {
    typeName: 'Anrede',
    ...kontaktModel.model.anrede,
    ...{
      value: 'anrede'
    }
  },
  {
    typeName: "Wettspiele",
    query: true,
    reference: true,
    required: true,
    rules: "notEmptyRules",
    listName: "competitions",
    label: "Wettspiele",
    value: "name",
    short: "name",
    long: "name",
    model: {
      id: false,
      name: "Wettspiel"
    }
  },
  {
    typeName: 'Titel',
    ...kontaktModel.model.titel,
    ...{
      value: 'name'
    }
  },
  {
    typeName: 'Clubverwaltungssoftware',
    ...vereinsModel.model.clubverwaltungssoftware,
    ...{
      value: 'name'
    }
  },
  {
    typeName: 'Geschlecht',
    ...turnierModel.model.gender,
    ...{
      value: 'geschlecht'
    }
  },
  {
    typeName: 'Turnierarten',
    ...turnierModel.model.turnierart,
    ...{
      value: 'name'
    }
  },
  {
    typeName: 'TurnierBereich',
    ...turnierModel.model.turnier_bereich,
    ...{
      value: 'bereich'
    }
  }, {
    typeName: 'Altersklasse',
    ...turnierModel.model.age_group,
    ...{
      value: 'altersklasse'
    }
  }, {
    typeName: 'League',
    ...mannschaftModel.model.league,
    ...{
      value: 'name'
    }
  }, {
    typeName: 'SubLeague',
    ...mannschaftModel.model.sub_league,
    ...{
      value: 'name'
    }
  },
  {
    typeName: 'KontaktPosition',
    ...kontaktModel.model.kontakt_position,
    ...{
      value: 'position'
    }
  }
];
const listObject = listsConfig.reduce((obj, cur) => ({...obj, [cur.typeName]: cur}), {});

export default {
  props: {
    formModel: {
      type: [Array,Object],
      required: false
    },
  },
  name: 'FormEditor',
  components: {
    FormEditorChip,
    draggable,
    EditForm: () => import('@/components/Forms/EditForm'),
    FormEditor: () => import('@/components/Forms/FormEditor'),
  },
  methods: {
    editSubForm(i) {
      this.$set(this.subFormDialog, i, true);
    },
    saveSubModel(i) {
      this.subFormDialog[i] = false;
      this.modelArray[i].subform = this.$refs['subForm' + i][0].modelArray;
    },
    addFormItem() {
      this.modelArray.push({name: 'neu', label: 'Neu'});
    },
    chipClick(item, chipType, i) {

      if (this.activeChips[i] === chipType) {
        //this.activeChips[i] = null;
        this.$set(this.activeChips, i, null);
        this.disableDrag = this.activeEditorCount > 0;
        return
      }
      this.$set(this.activeChips, i, chipType);
      this.disableDrag = this.activeEditorCount > 0;
      //this.activeChips[i] = chipType;
    },
    deleteFormItem(i) {
      this.modelArray.splice(i, 1);
      this.$set(this.activeChips, i, null);
      this.disableDrag = this.activeEditorCount > 0;
    },
    copyFormItem(i){
      this.modelArray.splice(i, 0, clonedeep(this.modelArray[i]));
    }
  },
  computed: {
    formFields() {
      return this.modelArray.map(m => m.name);
    },
    activeEditorCount() {
      return this.activeChips.filter(x => x !== null && x !== undefined).length;
    },
    chipFormModels() {
      const that = this;
      return {
        db: {
          name: {
            label: 'Datenbankfeld',
            required: true,
            rules: 'noSpecialChars'
          }
        },
        label: {
          label: "Anzeigename"
        },
        type: {
          type: {
            type: 'select',
            options: [
              'text',
              'tel',
              'email',
              'date',
              'datetime',
              'checkbox',
              'select',
              'radio',
              'addable',
              'formHTML',
              'checkbox-group'
            ],
            change(item) {
              if (item.type === 'addable' && item.subform === undefined) {
                that.$set(item, 'subform', {...that.defaultSubForm})
              }
              //console.log(that.chipFormModels);
            }
          },
          subMax: {
            label: 'Maximale Anzahl?',
            type: 'number',
            condition: (item) => {
              return item.type === "addable";
            },
            tooltip: "Für unbegrenzte Eingaben, leer lassen."
          },
          /*maxCheck: {
            label: 'Maximale Anzahl?',
            type: 'number',
            condition: (item) => {
              return item.type === 'checkbox-group';
            },
            change: (item) => {
              const maxCheckNumber = Number(item.maxCheck);
              if(!isNaN(maxCheckNumber) && maxCheckNumber > 0){
                this.$set(item,"maxRule",String.raw`v => !Array.isArray(v) || v.length > ${item.maxCheck} || 'Es dürfen maximal ${item.maxCheck} Optionen ausgewählt werden.'`);
              }else{
                this.$set(item,"maxRule",undefined);
              }
            },
            tooltip: "Für unbegrenzte Eingaben, leer lassen."
          },*/
          addBtnTxt: {
            label: 'Button-Text',
            type: 'text',
            condition: (item) => {
              return item.type === "addable";
            },
            default: "Hinzufügen"
          },
          html: {
            label: 'HTML-Code',
            type: 'html',
            condition: (item) => {
              return item.type === "formHTML";
            }
          },
          reference: {
            label: 'Werte aus Liste?',
            type: 'checkbox',
            condition: (item) => {
              return item.type === "select";
            },
          },
          multiple: {
            label: 'Mehrfachauswahl?',
            type: 'checkbox',
            condition: (item) => {
              return item.type === "select";
            },
          },
          options: {
            type: 'options',
            condition: (item) => {
              return item.type === "radio" || (item.type === "checkbox-group" || item.type === "select") && (item.reference === false || item.reference === undefined);
            },
          },
          // 
          typeName: {
            label: "Listentyp",
            type: 'select',
            options() {
              return listsConfig.map(x => x.typeName);
            },
            condition: (item) => {
              return (item.type === "select" || item.type === "checkbox-group") && item.reference === true;
            },
            change(item) {
              console.log(item.typeName)
              item.name = item.typeName;
              for (let settingKey in listObject[item.typeName]) {
                item[settingKey] = listObject[item.typeName][settingKey];
              }
            }
          },
          filter: {
            label: "Filter",
            type: 'json',
            condition: (item) => {
              return (item.type === "select" || item.type === "checkbox-group") && item.reference === true;
            }
          },
          /*
          Für Auswahl von Zusatzinformationen im Formular-Editor.
          -> Im UI kann eine Option ausgewählt werden, dass zusätzliche Wettspielinformationen in dem select-Element der Kampagnen ausgegeben wird.
          -> Diese Option ist ist an sich nur für Referee-Einladungen relevant.
          */
          customOptions: {
            label: 'Detailliertere Auswahl (+ Zusatzinformationen (Start - Ende etc.)) der Wettspiele anzeigen',
            type: 'checkbox',
            condition: (item) => {
              return item.type === "select" && item.typeName == "Wettspiele";
            },
          },
        },
        validation: {
          required: {
            label: 'Pflichtfeld?',
            type: 'checkbox'
          },
          rules: {
            label: 'Logik',
            type: 'select',
            options: [{d: 'Nicht leer!', v: 'notEmptyRules'}, {d: 'E-Mail-Adresse', v: 'emailRules'}, {
              d: 'Nur Zahlen',
              v: 'numberRules'
            }, {d: 'Datenschutz', v: 'privacy'}],
            condition(item) {
              return item.required;
            }
          }
        },

        colspan: {
          customWidth: {
            label: "Breite anpassen",
            type: 'checkbox',
            change(item) {
              if (!item.customWidth) {
                item.colspan = undefined;
              }
            }
          },
          colspan: {
            condition(item) {
              return item.customWidth || item.colspan !== undefined
            },
            label: 'Breite (1 bis 12)',
            required: true,
            rules: 'numberRules'
          }
        },
        condition: {
          enableCondition: {
            label: "Anzeigebedingung?",
            type: 'checkbox',
            change(item) {
              if (!item.enableCondition) {
                item.condition = undefined;
                item.conditionFields = undefined;
              }
            }
          },
          jsCondition: {
            type: 'checkbox'
          },
          conditionFields: {
            condition(item) {
              return item.jsCondition === false || item.jsCondition === undefined && item.enableCondition
            },
            label: 'Feld auswählen',
            subText: "Die Anzeige dieser Eingabe ist abhängig von der Beantwortung der angegebenen Felder.",
            required: true,
            type: 'select',
            multiple: true,
            options() {
              return that.formFields;
            },
            change(item) {
              const cf = (editItem) => {
                let r = true;
                /*if(item.conditionValues !== undefined && item.conditionValues !== ''){
                  const conditionValues = item.conditionValues.split(",");
                  item.conditionFields.forEach((f,i) => {
                   if (editItem[f] !== conditionValues[i] || editItem[f] === '' || editItem[f] === undefined) {
                      r = false;
                    }
                  });
                }else{
                  item.conditionFields.forEach(f => {
                    if (typeof editItem[f] === 'boolean' && !editItem[f]) {
                      r = false;

                    } else if (editItem[f] === null || editItem[f] === '' || editItem[f] === undefined) {
                      r = false;
                    }
                  });
                }*/

                item.conditionFields.forEach(f => {
                  if (typeof editItem[f] === 'boolean' && !editItem[f]) {
                    r = false;

                  } else if (editItem[f] === null || editItem[f] === '' || editItem[f] === undefined) {
                    r = false;
                  }
                });

                return r;
              }
              that.$set(item, 'condition', cf);
              // item.condition =
            }
          },
          condition: {
            type: 'textarea',
            condition(item) {
              return item.jsCondition === true
            }
          },

          /*conditionValues: {
            condition(item) {
              return item.enableCondition || item.condition !== undefined
            },
            label: 'Werte für Bedingungsfelder',
            subText: "Ausgewählte Felder der Anzeigebedingung müssen diese Wertte haben",
            required: true,
            type: 'text'
          }*/
        }
      }
    },
  },
  data() {
    return {
      realSubformItem: {},
      subFormDialog: [],
      defaultSubForm: [
        {
          "name": "vorname",
          "label": "Vorname",
          "required": true,
          "rules": "notEmptyRules",
          "customWidth": true,
          "colspan": "5"
        },
        {
          "name": "nachname",
          "label": "Nachname",
          "required": true,
          "rules": "notEmptyRules",
          "customWidth": true,
          "colspan": "5"
        }],
      modelArray: Array.isArray(this.$props.formModel)
          ?
          [...this.$props.formModel]
          :
          Object.keys(this.$props.formModel).map(name => {
            const modelProps = typeof this.$props.formModel[name] === 'string' ? {label: this.$props.formModel[name]} : this.$props.formModel[name];
            return {
              name: name,
              ...modelProps
            }
          }),
      disableDrag: false,
      selectOptions: {},
      loadedOptions: [],
      selectOptionsType: null,
      activeChips: [],
      emtpyEditForm: {},
      newForm: null,
      editItems: [],
      activeDialogs: [],
      /*newFieldModel:{
        type:{
          type: 'select',
          options:[
            {
              value: 'select',
              label: 'Auswahl',
              model: {
                anrede: null//require("@/config/models/Kontakte").model.anrede
              }
            },
            {
              value : 'checkbox',
              label : 'Checkbox',
              model : {
                "DatenschutzBestaetigt": {
                  label: `Ich akzeptiere die <a href="/Datenschutz">Datenschutzbestimmungen</a>`,
                  type: "checkbox",
                  colspan: 4,
                  required: true
                }
              }
            },
            {
              value: 'text',
              label: 'Textfeld einzeilig',
              model: {
                name:{
                  type: 'text',
                  required: true,
                  label: 'Freitext Eingabe'
                }
              }
            },
            {
              value: 'textarea',
              label: 'Textfeld mehrzeilig',
              model: {
                bemerkung:{
                  type: 'textarea',
                  label: 'Bemerkung eingeben'
                }
              }
            },
            {
              value: 'email',
              label: 'E-Mail-Eingabe',
              model: {
                name:{
                  type: 'email',
                  required: true,
                  rules: "emailRules",
                  label: 'E-Mail Eingabe'
                }
              }
            },
            {
              value: 'tel',
              label: "Telefon Eingabe",
              model: {
                telefon: {
                  type: "tel",
                  label: "Telefon"
                }
              }
            }
          ]
        }
      }*/
    };
  }
}
</script>
