<template>
  <v-tooltip :disabled="tooltip===undefined || tooltip === ''"
             top
  >
    <template v-slot:activator="{ on, attrs }">
      <v-chip
          active-class="secondary"
          small
          v-bind="attrs"
          @click="$emit('click')"
          v-on="on"
          :disabled="disabled"
      >
        <v-icon left>
          {{ icon }}
        </v-icon>
        {{ label }}
      </v-chip>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>

<script>


export default {
  /* props: [
     "icon",
     "label",
     "tooltip",
     "model",
     "disabled"
   ],*/
  props: {
    "icon": String,
    "label": String,
    "tooltip": String,
    "model": [Object, Array],
    "disabled": Boolean,
  },
  name: 'FormEditorChip',
  data() {
    return {
      showForm: false
    };
  }
}
</script>
